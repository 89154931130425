import React from "react"
import PageTransition from 'gatsby-v2-plugin-page-transitions';
import Layout from "./Layout"
import SEO from "./Seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Ups! Not found.. | Oriol Hilari" />
    <PageTransition>
      <h1 style={{textAlign: 'center'}}>Ups! There's nothing here...</h1>
      <p style={{textAlign: 'center'}}>This route doesn&#39;t exist... the sadness.</p>
      <div style={{textAlign: 'center'}}>
        <iframe title="There's nothing here. 404." style={{textAlign: 'center'}} src="https://giphy.com/embed/OSuaE6AknuRc7syZXp" width="480" height="360" frameBorder="0" allowFullScreen></iframe>
      </div>
    </PageTransition>
  </Layout>
)

export default NotFoundPage
